import React from "react"
import MainLayout from "../components/mainLayout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import record from '../images/record.jpg'
import style from "./about.module.scss"

const AboutPage = () => {
  const content = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: {fileAbsolutePath: {regex: "/about/"}}
      ) {
        edges {
          node {
            frontmatter {
              title
            }
            html
          }
        }
      }
      file(relativePath: { eq: "melinda2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  function createMarkup() {
    return {__html: content.allMarkdownRemark.edges[0].node.html};
  }

  return (
    <MainLayout>
      <SEO title="About" />
      <Banner primaryText={'About'} backgroundImg={record}/>
      <div className={style.aboutBody}>
        <div className={style.aboutLeftColumn}>
          <div>
            <Img fluid={content.file.childImageSharp.fluid} />
          </div>
          <small>Melinda Colaizzi, Founder + Principal at Pitch Consulting</small>
        </div>
        <div className={style.aboutRightColumn}>
          <div dangerouslySetInnerHTML={createMarkup()} />
        </div>
      </div>
    </MainLayout>
  )
}

export default AboutPage
